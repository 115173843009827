import React, { useEffect, useContext } from 'react';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
    SttButton,
    SttContainer,
    SttTranslateHook,
} from '@stt-componentes/core';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import DadosExame from './dados-exame';

const Visualizacao = (props) => {
    useSignals();

    const { imprimirFn, imprimirProtocoloFn, imprimirTermoFn } = props;

    const idExame = useSignal(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useEffect(() => {
        if (id) {
            navigate('/exames', { replace: true });
            navigate('/exames/visualizar', { state: { exame: Buffer.from(id, 'base64').toString('utf-8') } });
        }

        if (location.state?.exame) {
            idExame.value = location.state.exame;
        }
        
    }, [location.state?.exame]);

    const voltar = () => {
        navigate('/exames', { state: { from: 'visualizar' } });
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                idExame.value &&
                <DadosExame 
                    id={idExame} 
                    imprimirFn={imprimirFn}
                    imprimirProtocoloFn={imprimirProtocoloFn}
                    imprimirTermoFn={imprimirTermoFn}
                    voltarFn={voltar}
                />
            }
        </SttContainer>
    )
}

export default Visualizacao;