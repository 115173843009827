import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import StickyBox from "react-sticky-box";
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttGrid,
    SttButton,
    SttHidden,
    SttLoading,
    SttTranslateHook,
} from '@stt-componentes/core';
import Laudo from './laudo';
import Imagens from '../anexos/imagens';
import Invalidar from '../exame/invalidar';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';
import laudar from '../../signals/laudo';

const useStyles = makeStyles(theme => ({
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalBody: {
        overflow: 'hidden'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const ContainerLaudo = ({ exame, idRede, callbackProximoExame, notificar }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const POLISSONO_API_BASE_URL = global.gConfig.url_base_polissonografia;

    const location = useLocation();
    const arquivosExame = useSignal([]);
    const contextoFabricalaudo = useSignal(location.pathname === '/laudo');
    const invalidar = useSignal(false);
    const imagensAtualizadas = useSignal(false);

    const handleCloseAlerta = () => {
        if (contextoFabricalaudo.value && callbackProximoExame) {
            callbackProximoExame();
        } else {
            laudar.value = false;
        }
    };

    const cancelarEmissao = () => {
        axios.post(`${POLISSONO_API_BASE_URL}/laudo/cancelar`, { idExame: exame.value.exame.id_exame }, { headers: getHeaders() })
            .catch(err => console.log(err));
    };
    
    useEffect(() => {
        if (!imagensAtualizadas.value) {
            imagensAtualizadas.value = true;
        }
    }, [imagensAtualizadas.value]);

    const voltar = () => {
        // Controle de componentes. Volta para tela anterior
        laudar.value = false;

        // Cancela emissão do laudo
        cancelarEmissao();
    };

    const ignorarExame = () => {
        notificar(strings.ignorandoExame);
        // Insere exame ignorado
        axios.post(`${POLISSONO_API_BASE_URL}/laudo/ignorar-exame`, { idExame: exame.value.exame.id_exame }, { headers: getHeaders(), timeout: 15000 })
            .then((resposta) => {
                // Cancela emissão do laudo
                cancelarEmissao();
                // Carrega o próximo exame
                callbackProximoExame();
            })
            .catch(err => {
                console.log(err);
                alerta.value = {
                    title: strings.erro,
                    message: strings.erroIgnorarExame,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                                handleCloseAlerta();
                            }
                        }
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        handleCloseAlerta();
                    }
                };
            });
    };

    return (
        <>
            <div className={classes.carregando}>
                <SttLoading
                    open={!exame.value && !contextoFabricalaudo.value}
                    text={strings.carregandoExame}
                />
            </div>
            {
                exame.value &&
                <>
                    <SttGrid container spacing={1}>
                        <SttGrid item xs={12} className={classes.wrapperBotao}>
                            {
                                <SttButton
                                    variant="contained"
                                    danger={+true}
                                    onClick={() => invalidar.value = true}
                                >
                                    {strings.invalidar}
                                </SttButton>
                            }

                            {
                                !contextoFabricalaudo.value
                                    ?
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        onClick={voltar}
                                    >
                                        {strings.voltar}
                                    </SttButton>
                                    :
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        onClick={ignorarExame}
                                    >
                                        {strings.proximoExame}
                                    </SttButton>
                            }


                        </SttGrid>
                    </SttGrid>

                    <SttGrid container spacing={1}>
                        <SttHidden mdUp>
                            <SttGrid item xs={12}>
                                {
                                    imagensAtualizadas.value &&
                                    <Imagens idExame={exame.value.exame.id_exame} />
                                }
                            </SttGrid>
                            <SttGrid item xs={12}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} arquivosExame={arquivosExame} laudar={laudar} imagensAtualizadas={imagensAtualizadas} />
                            </SttGrid>
                        </SttHidden>
                        <SttHidden only={['xs', 'sm']}>
                            <SttGrid item xs={6}>
                                <StickyBox>
                                    {
                                        imagensAtualizadas.value &&
                                        <Imagens idExame={exame.value.exame.id_exame} />
                                    }
                                </StickyBox>
                            </SttGrid>
                            <SttGrid item xs={6}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} arquivosExame={arquivosExame} laudar={laudar} imagensAtualizadas={imagensAtualizadas} />
                            </SttGrid>
                        </SttHidden>
                    </SttGrid>
                </>
            }
            {
                invalidar.value &&
                <Invalidar
                    idExame={exame.value.exame.id_exame}
                    idInstituicao={exame.value.exame.id_instituicao}
                    invalidar={invalidar}
                    idRede={idRede}
                    callback={callbackProximoExame}
                />
            }
        </>
    );
};

export default ContainerLaudo;