import React, { useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttLoading,
    SttTranslateHook,
    SttTabs
} from '@stt-componentes/core';
import { useMoment } from '../../hooks';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';
import laudar from '../../signals/laudo';
import ContainerLaudo from './containerLaudo';
import HistoricoPaciente from './historicoPaciente';

const useStyles = makeStyles(theme => ({
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalBody: {
        overflow: 'hidden'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const Index = ({ idExame, idRede, callbackProximoExame, callbackExameCarregado, notificar }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const POLISSONO_API_BASE_URL = global.gConfig.url_base_polissonografia;
    const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;

    const location = useLocation();
    const moment = useMoment();
    const exame = useSignal(null);
    const abas = useSignal([]);
    const abaAtiva = useSignal('1');
    const arquivosExame = useSignal([]);
    const contextoFabricalaudo = useSignal(location.pathname === '/laudo');
    const imagensAtualizadas = useSignal(false);
    const historicoCarregado = useSignal(false);

    const handleCloseAlerta = () => {
        if (contextoFabricalaudo.value && callbackProximoExame) {
            callbackProximoExame();
        } else {
            laudar.value = false;
        }
    }

    useEffect(() => {
        if (!imagensAtualizadas.value) {
            imagensAtualizadas.value = true;
        }
    }, [imagensAtualizadas.value]);

    useEffect(() => {
        axios.get(`${POLISSONO_API_BASE_URL}/laudo/${idExame}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    let { data } = response.data;
                    let dataMoment = moment(data.exame.data_nascimento_paciente);
                    if (dataMoment.isValid()) {
                        var now = moment();
                        var dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
                        var idade = moment.duration(now.diff(dataMomentFormatada));
                        var anos = idade.years();
                        var meses = idade.months();
                        var dias = idade.days();
                        data.exame.data_nascimento_paciente_formatada += ` (${anos} ano(s), ${meses} mes(es) e ${dias} dia(s))`
                    }
                    exame.value = data;
                    if (data.arquivos) {
                        arquivosExame.value = data.arquivos;
                    }
                    if (contextoFabricalaudo.value) {
                        callbackExameCarregado();
                    }
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.erroDesconhecido;
                let arrMensagem = [];
                if (response) {
                    const { data } = response;
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                }

                alerta.value = {
                    title: strings.erro,
                    message: msg,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                                handleCloseAlerta();
                            }
                        }
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        handleCloseAlerta();
                    }
                };
            });

        return () => {
            laudar.value = false;
            exame.value = null;
            historicoCarregado.value = false;
        }
    }, [idExame]);

    useSignalEffect(() => {
        if (exame.value && !historicoCarregado.value) {
            axios
                .get(`${EXAMES_API_BASE_URL}/laudo/historico/${idExame}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        if (response.data.data.length > 0) {
                            let dadosAbas = [];

                            // Aba histórico
                            dadosAbas.push({
                                historico: response.data.data,
                                titulo: strings.historico,
                                permanente: true,
                                abas,
                                abaAtiva,
                                conteudo: HistoricoPaciente,
                            });

                            // Aba laudo
                            dadosAbas.push({
                                exame: exame,
                                idRede,
                                callbackProximoExame,
                                callbackExameCarregado,
                                notificar,
                                permanente: true,
                                titulo: strings.laudo,
                                conteudo: ContainerLaudo,
                            });

                            abas.value = dadosAbas;
                        }
                    }
                })
                .catch(err => console.log(err))
                .finally(() => {
                    historicoCarregado.value = true;
                });
        }
    });

    return (
        <>
            <div className={classes.carregando}>
                <SttLoading
                    open={!exame.value && !contextoFabricalaudo.value}
                    text={strings.carregandoExame}
                />
            </div>
            {
                exame.value &&
                (
                    abas.value.length > 0 &&
                    <SttTabs
                        className={classes.tabs}
                        abaAtiva={abaAtiva.value}
                        abas={abas.value}
                        permanente={true}
                        canClose={true}
                        handleCloseTab={(indice) => {
                            abas.value.splice(indice, 1);
                            abaAtiva.value = abas.value.length - 1 + '';
                        }}
                    />
                    ||
                    <ContainerLaudo 
                        exame={exame} 
                        idRede={idRede} 
                        callbackProximoExame={callbackProximoExame} 
                        notificar={notificar}
                    />
                )
            }
        </>
    );
};

export default Index;